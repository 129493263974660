import {
  NanoIcon,
  NanoInput,
  NanoSpinner,
} from "@nanoporetech-digital/components-react";
import { useCallback, useContext, useEffect, useState } from "react";
import RegistrationIdContext from "../../../context/RegistrationIdContext";
import { useApplyTransitionMutation } from "../../../services/janus/registrations/applyTransition";
import { useGetRegistrationQuery } from "../../../services/janus/registrations/getRegistration";
import { useGetRegistrationOptionsMutation } from "../../../services/janus/registrations/getRegistrationOptions";
import { useUpdateRegistrationMutation } from "../../../services/janus/registrations/updateRegistration";
import { LayoutContent } from "../../layout/Layout";
import LoadingOverlay from "../../loading-overlay/LoadingOverlay";

export default function CatalogueSelectAccount() {
  const id = useContext(RegistrationIdContext);
  if (id === null) {
    throw new Error("Registration not selected.");
  }

  const [filter, setFilter] = useState<string>("");

  const {
    data: registration,
    refetch: registrationRefetch,
    isFetching: registrationIsLoading,
  } = useGetRegistrationQuery(id);

  const [getOptions, optionsResponse] = useGetRegistrationOptionsMutation();
  const [update, updateResponse] = useUpdateRegistrationMutation();
  const [transition, transitionResponse] = useApplyTransitionMutation();

  const error =
    optionsResponse.error || transitionResponse.error || updateResponse.error;

  if (error) {
    throw new Error(JSON.stringify(error));
  }

  const [transitionName, setTransitionName] = useState<
    string | null | undefined
  >("");

  useEffect(() => {
    setTransitionName(registration?.actions.determined.transition);
  }, [registration]);

  const handleSelect = useCallback(
    async (option: string | null) => {
      await update({ id, data: { account_join_selection: option } }).unwrap();
      if (transitionName) {
        await transition({ id, transitionName }).unwrap();
        registrationRefetch();
      }
    },
    [registrationRefetch, transition, id, update, transitionName]
  );

  useEffect(() => {
    getOptions(id);
  }, [getOptions, id]);

  useEffect(() => {
    if (optionsResponse.data && !registrationIsLoading) {
      const { blocked, options = [] } = optionsResponse.data;
      if (transitionName && !(blocked || options.length)) {
        transition({ id, transitionName }).unwrap().then(registrationRefetch);
      }
    }
  }, [
    registrationIsLoading,
    optionsResponse,
    transitionName,
    transition,
    id,
    registrationRefetch,
  ]);

  const options = (optionsResponse.data?.options || []).filter(
    (option) =>
      option.account_name.toLowerCase().includes(filter.toLowerCase()) ||
      option.account_address.toLowerCase().includes(filter.toLowerCase())
  );

  if (optionsResponse.error) {
    console.log(optionsResponse.error);
  }

  if (optionsResponse.isError) {
    throw new Error("Options could not be loaded.");
  }

  if (optionsResponse.isLoading) {
    return (
      <LayoutContent>
        <h1>Please wait while we check your details</h1>
        <p>This process may take up to 30 seconds.</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "3rem",
            padding: "2rem 0",
          }}
        >
          <NanoSpinner style={{ width: "10rem" }} />
        </div>
      </LayoutContent>
    );
  }

  if (!optionsResponse.data?.blocked)
    return (
      <>
        <LoadingOverlay show={registrationIsLoading} />
        <LayoutContent>
          {optionsResponse.data?.options.length ? (
            <>
              <h1>Join an existing organisation</h1>
              <p>
                Choosing an organisation from the list below will speed up your
                registration.
              </p>
              {optionsResponse.data?.options.length > 10 ? (
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <NanoInput
                    style={{ width: "15rem", float: "right" }}
                    floatLabel
                    label="Filter options"
                    value={filter}
                    onNanoChange={(event) =>
                      setFilter(event.target.value || "")
                    }
                  />
                </div>
              ) : null}
            </>
          ) : null}
          <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
            {options.map((option) => (
              <button
                onClick={() => handleSelect(option.account_number)}
                key={option.account_number}
                className="search-option"
              >
                <NanoIcon
                  name="light/building"
                  className="option__icon app-icon"
                />
                <div className="option__text">
                  <div className="option__title">
                    {option.account_number} - {option.account_name}
                  </div>
                  <div className="option__meta">{option.account_address}</div>
                </div>
              </button>
            ))}
          </div>
          <br />
          {optionsResponse.isLoading ? null : (
            <button
              className="button button-as-link"
              onClick={() => handleSelect(null)}
            >
              Create a new account for{" "}
              <strong>{registration?.organisation_name || ""}</strong> instead.
            </button>
          )}
        </LayoutContent>
      </>
    );
  return (
    <LayoutContent>
      <h1>Please get in touch</h1>
      <p>
        Your account cannot be set up automatically. Please contact{" "}
        <a href="https://nanoporetech.com/support/">customer support</a> to
        complete registration.
      </p>
    </LayoutContent>
  );
}
