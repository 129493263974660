import { useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useFormSubmit } from "../../../common/form-utils";
import { useGetCountryOptionsQuery } from "../../../services/janus/countries/getCountryOptions";
import { NamedPlace } from "../../../types/options";
import ActionButtonBar from "../../action-buttons/ActionButtonBar";
import InputConfirmBox from "../../input-confirm-box/InputConfirmBox";
import InputOrganisationCountry from "../../input-organisation-country/InputOrganisationCountry";
import InputOrganisationName from "../../input-organisation-name/InputOrganisationName";
import InputOrganisationRegion from "../../input-organisation-region/InputOrganisationRegion";
import InputOrganisationType from "../../input-organisation-type/InputOrganisationType";
import InputText from "../../input-text/InputText";
import InputYesNo from "../../input-yes-no/InputYesNo";
import { LayoutContent } from "../../layout/Layout";
import LoadingOverlay from "../../loading-overlay/LoadingOverlay";

const termsLink =
  "https://a.storyblok.com/f/196663/x/61f8ef3001/end-customer-licence-terms_distributors_01june2024.pdf";

export default function CataloguePersonalDetails() {
  const {
    registration,
    form,
    control,
    handleSubmit,
    submitHandler,
    inProgress,
    updateRegistrationResult,
  } = useFormSubmit();

  const { data: countryOptions = [] } = useGetCountryOptionsQuery({
    channelPartner: registration.channel_partner || null,
  });

  const country = form.watch("organisation_country");

  const requiresPostalCode = useMemo(() => {
    const foundCountry = countryOptions.find(
      ({ id }) => id.toString() === (country === null ? "" : country).toString()
    );
    if (foundCountry) {
      return (foundCountry as NamedPlace).requires_postal_code;
    }
    return undefined;
  }, [country, countryOptions]);

  useEffect(() => {
    if (requiresPostalCode === false) {
      form.setValue("organisation_postal_code", "");
    }
  }, [form, requiresPostalCode]);

  useEffect(() => {
    form.setValue("terms_and_conditions_agreement_link", termsLink);
  });

  return (
    <FormProvider {...form}>
      <LoadingOverlay show={inProgress} />

      <form onSubmit={handleSubmit(submitHandler)}>
        <LayoutContent>
          <h3>Account registration</h3>
          <p>
            To complete the registration and unlock your devices, please confirm
            the following information.
          </p>
          <p style={{ fontSize: ".8rem" }}>
            Fields marked with * are required.
          </p>

          <h4>Organisation details</h4>
          <InputOrganisationCountry
            forChannelPartner={registration.channel_partner}
            label="Country*"
            name="organisation_country"
            control={control}
          />
          <InputOrganisationRegion
            countryFieldName="organisation_country"
            label="Region*"
            name="organisation_region"
            control={control}
          />
          {requiresPostalCode ? (
            <InputText
              className="width-25"
              name="organisation_postal_code"
              label="Zip/Postal code*"
              placeholder=""
              control={control}
            />
          ) : null}
          <InputOrganisationName control={control} />

          <InputOrganisationType control={control} label="Organisation type*" />
          <InputText
            className="width-40"
            name="organisation_website"
            label="Website*"
            placeholder=""
            autoCapitalize={false}
            control={control}
          />

          <h4>Confirmation of use</h4>
          <InputYesNo
            className="width-40"
            label="Are you intending to resell, distribute or provide the products to a third party? *"
            name="use_resell"
            control={control}
          />

          <InputYesNo
            className="width-40"
            label="Will the products be used for military or nuclear applications or military or nuclear end-use purposes? *"
            name="use_military_nuclear"
            control={control}
          />

          <p className="width-40">
            Please provide information regarding your intended use of Nanopore
            products and technology *
          </p>
          <InputText
            className="width-40"
            type="textarea"
            rows={4}
            name="use_statement"
            label=""
            placeholder=""
            control={control}
          />

          <p>
            Please review our{" "}
            <a target="_blank" rel="noopener noreferrer" href={termsLink}>
              terms and conditions
            </a>
            .
          </p>
          <InputConfirmBox
            className="width-40"
            label="I agree to Oxford Nanopore's terms and conditions"
            name="terms_and_conditions_agreement"
            control={control}
          />
        </LayoutContent>
        <ActionButtonBar
          submitStatus={updateRegistrationResult.status}
          submitResult={updateRegistrationResult.data}
          resetSubmit={updateRegistrationResult.reset}
        />
      </form>
    </FormProvider>
  );
}
