export type RegistrationId = string;

export enum RegistrationState {
  IR_NONINVITED_NEW = "IR_NONINVITED_NEW",
  IR_NONINVITED_DEVICE_DRAFT = "IR_NONINVITED_DEVICE_DRAFT",
  IR_UNVERIFIED = "IR_UNVERIFIED",
  IR_COMPLETE = "IR_COMPLETE",

  CATALOGUE_ENTRYPOINT = "CATALOGUE_ENTRYPOINT",
  CATALOGUE_PERSONAL_DETAILS = "CATALOGUE_PERSONAL_DETAILS",
  CATALOGUE_ORG_DETAILS = "CATALOGUE_ORG_DETAILS",
  CATALOGUE_EMAIL_VERIFICATION = "CATALOGUE_EMAIL_VERIFICATION",
  CATALOGUE_SELECT_ACCOUNT = "CATALOGUE_SELECT_ACCOUNT",
  CATALOGUE_COMPLETE = "CATALOGUE_COMPLETE",
  CATALOGUE_REPEAT = "CATALOGUE_REPEAT",
}

export enum RegistrationProgressState {
  EMPTY = "MT",
  POPULATING = "PG",
  SYNCING_SALESFORCE = "SS",
  SYNCED_SALESFORCE = "FS",
  SYNCING_OCTOPUS = "SO",
  SYNCED_OCTOPUS = "FO",
  REQUESTED_MYACCOUNT = "RM",
  CREATED_MYACCOUNT = "CM",
  APPROVED_MYACCOUNT = "AM",
  COMPLETED_SALESFORCE = "CF",
  FINISHED = "OK",
  FAILED = "F",
  FINISHED_PREEXISTING_ACR = "FP",
  FINISHED_PREEXISTING_DIRECT_ACR = "FD",
}

export interface RegistrationDevice {
  serial_number: string;
}

export interface ResolvedTransition {
  transition: string | null;
  combined_validation: TransitionReason[];
}

export interface RegistrationActions {
  all: RegistrationTransition[];
  determined: ResolvedTransition;
}

export interface PublicRegistration {
  id: RegistrationId;
  state: RegistrationState;
  personal_title: number | null;
  first_name: string | null;
  last_name: string | null;
  contact_email_unverified: string | null;
  contact_telephone: string | null;
  account_join_selection: string | null;

  marketing_opt_in_email: boolean | null;

  password: string | null;
  has_password: boolean;

  organisation_name: string | null;
  organisation_duns: string | null;
  organisation_type: number | null;
  organisation_website: string | null;
  organisation_country: string | null;
  organisation_region: string | null;
  organisation_postal_code: string | null;
  device_serial_number_unverified: string | null;
  device_serial_number_verified: string | null;
  use_resell: boolean | null;
  use_military_nuclear: boolean | null;
  use_statement: string | null;
  terms_and_conditions_agreement: boolean | null;
  terms_and_conditions_agreement_link: string | null;

  email_verification_submitted_code: string;

  channel_partner: number | null;

  progress_state: RegistrationProgressState;
  actions: RegistrationActions;

  google_recaptcha_v2_token: string | null;

  sales_choice_direct: boolean | null;
  sales_choice_partner: string | null;

  interests_products: string[] | null;
  interests_research_areas: string[] | null;
  interests_role: string | null;
  interests_applied_uses: string[] | null;
  interests_techniques: string[] | null;

  address_shipping_company: string | null;
  address_shipping_street: string | null;
  address_shipping_city: string | null;
  address_shipping_region_name: string | null;
  address_shipping_region_code: string | null;
  address_shipping_postal_code: string | null;
  address_shipping_country_code: string | null;
  address_shipping_country_name: string | null;
}

export enum TransitionType {
  TRANSITION_BACK = "TRANSITION_BACK",
  TRANSITION_FORWARD = "TRANSITION_FORWARD",
  TRANSITION_COMPLETE = "TRANSITION_COMPLETE",
  TRANSITION_CANCEL = "TRANSITION_CANCEL",
}

export enum ReasonType {
  ERROR = "ERROR",
  DISCRIMINATOR = "DISCRIMINATOR",
  DISCRIMINATED = "DISCRIMINATED",
  HIDDEN = "HIDDEN",
}

export interface TransitionReason {
  name: string;
  reason: string;
  field: string;
  reason_type: ReasonType;
}

export interface TransitionButtonProps {
  index: number;
  alignment: "end" | "start";
  variant: "primary" | "secondary";
  label: string;
  icon: string;
}

export interface RegistrationTransition {
  name: string;
  available: boolean;
  validation: TransitionReason[];
  button: null | TransitionButtonProps;
  transition_type: TransitionType;
  priority: number;
}

export function transitionIsForward(transition: RegistrationTransition) {
  return (
    transition.transition_type === TransitionType.TRANSITION_FORWARD ||
    transition.transition_type === TransitionType.TRANSITION_COMPLETE
  );
}
